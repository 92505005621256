<template>
  <div>
    <div class="bg"></div>
    <div class="content">
      <div class="top">
        <img
          class="logo"
          src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220608_173154_62a06c8ab5efa.png"
        />
        <h2 class="van-ellipsis">{{ user.title }}</h2>
        <div class="icon">
          <img
            src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220608_173538_62a06d6aa9cbc.png"
            alt="分享"
          />
          <img
            src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220608_173604_62a06d84b4532.png"
            alt="设置"
            @click="myInfo"
          />
        </div>
      </div>
      <p class="desc">手机号：{{ user.phone }}</p>
      <p class="desc">联系地址：{{ user.address }}</p>
      <div class="wallet">
        <p class="my">我的钱包</p>
        <div class="money">
          <p>¥{{ user.bond_money }}</p>
          <p @click="moneyDetail">账单明细</p>
        </div>
        <div class="btn">
          <button class="btn-1" @click="withdrawal">提现</button>
          <button class="btn-2" @click="pay">充值</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup } from 'vant'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  data() {
    return {
      user: {},
    }
  },
  created() {
    const that = this
    this.$http('/agent/info').then((res) => {
      that.user = res.data
      this.$state.user = res.data
    })
  },
  methods: {
    myInfo() {
      this.$router.push('myInfo')
    },
    moneyDetail() {
      this.$router.push('moneyDetail')
    },
    withdrawal() {
      this.$router.push('withdrawal')
    },
  },
}
</script>

<style lang="less" scoped>
.bg {
  background: url(https://img1.baidu.com/it/u=698938107,755743343&fm=253&fmt=auto&app=138&f=GIF?w=375&h=176)
    no-repeat;
  background-size: 100%;
  height: 176px;
}
.content {
  background: #fafafa;
  padding: 0 32px;
  border-radius: 16px 16px 0 0;
  margin-top: -25px;
  height: calc(100vh - 200px);
  .top {
    position: relative;
    height: 65px;
    .logo {
      width: 80px;
      height: 80px;
      position: absolute;
      bottom: 20px;
      object-fit: contain;
    }
    h2 {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      position: absolute;
      bottom: 22px;
      padding: 0;
      margin: 0;
      left: 95px;
      width: 150px;
    }

    .icon {
      position: absolute;
      bottom: 20px;
      right: 0;
      img {
        width: 32px;
        height: 32px;
        padding-left: 8px;
      }
    }
  }
  .desc {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 20px;
    padding: 0;
    margin: 0;
    padding-bottom: 5px;
  }
  .wallet {
    text-align: center;
    height: 180px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    .my {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      line-height: 20px;
    }
    .money {
      // display: flex;
      margin: 0 auto;
      // height: 44px;
      p:nth-child(1) {
        padding: 0;
        margin: 0;
        display: inline;
        font-size: 36px;
        font-weight: bold;
        // color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
      }
      p:nth-child(2) {
        padding: 0;
        margin: 0;
        display: inline;
        position: absolute;
        padding-left: 8px;
        font-size: 10px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 55px;
      }
    }
    .btn {
      padding-top: 36px;
      &-1 {
        width: 88px;
        height: 30px;
        background: #ffe6e0;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: none;
        margin-right: 22px;
        color: #f45950;
      }
      &-2 {
        color: #ffffff;
        width: 88px;
        height: 30px;
        background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: none;
      }
    }
  }
}
</style>
